import React, { useState, useEffect, Fragment } from 'react'
import { useStore } from '@nanostores/react'
import { cartData, isCartOpen } from '@stores/cartStore'

import Loader from '@components/Loader/Loader.jsx'
import ShoppingBag from '@components/Icons/ShoppingBag.jsx'
const CartCount = React.lazy(() =>
    import('@components/Header/GlobalNav/CartCount.jsx')
)

const NavCart = () => {
    const $cartData = useStore(cartData || null)
    const $isCartOpen = useStore(isCartOpen)
    const [numberOfItems, setNumberOfItems] = useState()
    const bundleCounts = new Set()

    // useEffect(() => {
    //     console.log('$isCartOpen', $isCartOpen)
    // }, [$isCartOpen])

    useEffect(() => {
        if (!$cartData?.lines) return

        const numItems = $cartData.lines.reduce((total, item) => {
            const isBundleProduct =
                item.node.attributes?.[0]?.key?.includes('bundle')

            if (isBundleProduct) {
                const bundleInfo = JSON.parse(item.node.attributes[0].value)
                if (
                    !bundleCounts.has(
                        `${bundleInfo.bundle_id}-${bundleInfo.bundle_size}`
                    )
                ) {
                    bundleCounts.add(
                        `${bundleInfo.bundle_id}-${bundleInfo.bundle_size}`
                    )
                    return total + item.node.quantity
                }
                return total
            }

            return total + item.node.quantity
        }, 0)

        setNumberOfItems(numItems)
    }, [$cartData])

    return (
        <div className='nav-cart relative mr-1'>
            {/* <ShoppingBag width="w-[16px]" /> */}
            <span
                title='Your Cart'
                className='text-ash cursor-pointer text-sm'
                onClick={() => {
                    // console.log('Cart clicked')
                    isCartOpen.set(!$isCartOpen)
                }}
            >
                <ShoppingBag width='w-[20px]' />
            </span>
            {numberOfItems &&
            numberOfItems !== null &&
            numberOfItems !== 0 &&
            numberOfItems !== '0' &&
            numberOfItems !== undefined &&
            numberOfItems !== 'undefined' &&
            typeof $cartData.lines !== 'undefined' &&
            $cartData.lines &&
            $cartData.lines.length > 0 ? (
                <CartCount numberOfItems={numberOfItems} />
            ) : null}
        </div>
    )
}

export default NavCart
